<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcManageGideonsMagazine }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage" class="magazine-home">
      <div class="container">
        <div class="card">
          <div class="card-header">
            <h4 class="mb-0">{{ translations.tcUploadPDFWithDetails }}</h4>
          </div>
          <div class="card-body py-3 flex-grow-1">
            <form>
              <div class="form-group">
                <label class="form-control-label">{{ translations.tcStartDate }}</label>
                <b-form-datepicker
                  class="mb-2"
                  v-model="forms.startdate"
                  :locale="prefCulture"
                  :label-help="translations.tcCalHelp"
                  :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcChooseDate"
                  :show-decade-nav="showDecadeNav"
                  :hide-header="hideHeader"
                >
                </b-form-datepicker>
              </div>
              <div class="form-group">
                <label class="form-control-label">{{ translations.tcEndDate }}</label>
                <b-form-datepicker
                  class="mb-2"
                  v-model="forms.enddate"
                  :locale="prefCulture"
                  :label-help="translations.tcCalHelp"
                  :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcChooseDate"
                  :show-decade-nav="showDecadeNav"
                  :hide-header="hideHeader"
                >
                </b-form-datepicker>
              </div>
              <div class="form-group">
                <label class="form-control-label">{{ translations.tcFileName }}</label>
                <input
                  type="text"
                  class="form-control input-mask"
                  v-model="forms.filename"
                  :placeholder="translations.tcFileName"
                />
              </div>
              <div class="form-group">
                <label class="form-control-label">{{ translations.tcFileDescription }}</label>
                <textarea
                  class="form-control"
                  v-model="forms.description"
                  :placeholder="translations.tcYouCanManuallyResizeTextArea"
                  rows="3"
                ></textarea>
              </div>
              <div class="form-group">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="forms.visibility"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                  {{ translations.tcVisibleOnline }}
                </b-form-checkbox>
              </div>
              <div class="form-group">
                <label class="form-control-label">{{ translations.tcFileUpload }}</label>
                <b-form-file
                  class="form-control"
                  v-model="forms.file"
                  :placeholder="translations.tcChooseFileOrDropHere"
                  :drop-placeholder="translations.tcDropFileHere"
                  :browse-text="translations.tcBrowse"
                ></b-form-file>
              </div>
              <div class="text-right">
                <button type="button" class="btn btn-sm btn-primary" @click="createMagazine">
                  {{ translations.tcSave }}</button
                >&nbsp;
                <button type="button" class="btn btn-sm btn-primary" @click="moveToHome">
                  {{ translations.tcCancel }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import magservice from '@/services/magazineService.js'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'magazine-admin',
  mixins: [translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      file: true,
      file2: null,
      createdDate: new Date().toDateString(),
      fields: ['file_name', 'Description', 'Date', 'view_file'],
      items: [],
      forms: {
        startdate: null,
        enddate: null,
        filename: null,
        description: null,
        file: null,
        visibility: null,
      },
      resultdata: '',
    }
  },
  async created() {
    this.isLoading = true
    await Promise.all([this.getViewTranslations(), this.getComponentTranslations('magazine', 'security-banner')]).then(
      (results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.isLoading = false
        this.items = results[1].data
      }
    )
  },
  components: {
    securityBanner: SecurityBanner,
  },
  methods: {
    moveToHome() {
      this.$router.push({ name: 'magazine-home' })
    },
    async createMagazine() {
      let formData = new FormData()
      formData.append('maz_title_name', this.forms.filename)
      formData.append('maz_start_date', this.forms.startdate)
      formData.append('maz_end_date', this.forms.enddate)
      formData.append('maz_description', this.forms.description)
      formData.append('maz_file_name', this.forms.file.name)
      formData.append('maz_is_visible', this.forms.visibility)
      formData.append('file', this.forms.file)
      formData.append('maz_key', '')
      formData.append('maz_delete_flag', false)
      await magservice
        .postMagazineFile(formData)
        .then(async (response) => {
          this.TGIAlert()
          this.resultdata = response.data
        })
        .catch((error) => {
          console.error('There is an error:', error.response)
        })
    },
    TGIAlert() {
      this.$swal({
        title: this.translations.tcSuccess || 'Success',
        text: this.translations.tcNewMagazineCreated || 'A New Magazine with Created',
        confirmButtonText: this.translations.tcOk,
        timer: 3000,
      }).then((r) => {
        this.$router.push({ path: '/programs/magazine/home' })
      })
    },
  },
  computed: {
    ...mapGetters({
      // isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return true
      // return this.$route.path.charAt(this.$route.path.length - 1) === '/'
      //   ? this.isInMenu(this.$route.path.slice(0, -1))
      //   : this.isInMenu(this.$route.path)
    },
  },
}
</script>

<style scoped>
.magazine-home {
  margin-bottom: 30px;
}
.btn-primary {
  color: #fff;
  background-color: #003954;
  border-color: #003954;
  -webkit-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15);
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15);
}
.btn-primary:hover {
  color: #fff;
  background-color: #003954;
  border-color: #003954;
}
</style>
