/* eslint-disable */
import axios from 'axios'

const API_ACTIVE_ROUTE = process.env.VUE_APP_MAGAZINE_APP
const API_CONFIG = {
  getAllMagazine: () => `${API_ACTIVE_ROUTE}/magazine/all`,
  postMagazineFile: data => `${API_ACTIVE_ROUTE}/magazine/upsert/file`
}

export default {
  getALLMagazine() {
    const route = API_CONFIG.getAllMagazine()
    return axios.get(route)
  },
  async postMagazineFile(data) {
    const route = API_CONFIG.postMagazineFile(data)
    return axios.post(route, data)
  }
}
